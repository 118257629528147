import {Location} from 'src/bus/location';

type TArgs = {
  type: Location.AddressTypes;
};

export const extractAddressType = ({type}: TArgs): Location.AddressTypes => {
  switch (type) {
    case 'region_name':
      return 'district_name';
    case 'district_name':
      return 'community';
    case 'community':
      return 'full_name';
    default:
      return 'district_name';
  }
};

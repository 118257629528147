import {StatisticActionTypes, StatisticState, types} from './types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Statistic} from './namespace';

const initialState = {
  items: [],
};

const slice = createSlice({
  name: 'Statistic',
  initialState,
  reducers: {
    saveItems: (
      state: StatisticState,
      action: PayloadAction<Statistic.ResFetchItems>,
    ) => {
      state.items = action.payload;
    },
    clearItems: (state: StatisticState) => {
      state.items = [];
    },
  },
});

export default slice.reducer;

export const statisticActions = {
  ...slice.actions,
  fetchItemsAsync: (
    payload: Statistic.ReqFetchItems,
  ): StatisticActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
};

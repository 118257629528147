import {FontWeight} from 'src/themes/context';
import styled from 'styled-components';
type TWrapperProps = {
  margin: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
  width: string;
};
type TInputProps = {
  dark?: boolean;
  error?: boolean;
};
export const Wrapper = styled.div<TWrapperProps>`
  width: ${({width}) => width};

  ${({margin}) => `
    margin-top: ${margin.top};
    margin-bottom: ${margin.bottom};
    margin-right: ${margin.right};
    margin-left: ${margin.left};
  `};
`;

export const Input = styled.input<TInputProps>`
  height: 100%;
  width: 100%;
  width: 100%;
  font-size: 15px;

  color: ${({theme}) => theme.text.default};
  font-weight: ${FontWeight.light};

  transition: 0.3s all;
  background-color: ${({theme, dark}) =>
    dark ? theme.background.dark_gray : theme.background.default};

  border: none;
  outline: none;
  cursor: pointer;
  border: none;

  padding-left: 16px;

  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px
      ${({theme, dark}) =>
        dark ? theme.background.dark_gray : theme.background.default}
      inset !important;
  }
  :-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px
      ${({theme, dark}) =>
        dark ? theme.background.dark_gray : theme.background.default}
      inset !important;
  }
  :-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px
      ${({theme, dark}) =>
        dark ? theme.background.dark_gray : theme.background.default}
      inset !important;
  }
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px
      ${({theme, dark}) =>
        dark ? theme.background.dark_gray : theme.background.default}
      inset !important;
  }
`;

export const InputWrapper = styled.div<TInputProps>`
  transition: 0.3s all;
  background-color: ${({theme, dark}) =>
    dark ? theme.background.dark_gray : theme.background.default};
  width: 100%;

  height: 48px;

  display: grid;
  border: 1px solid ${({theme}) => theme.border.gray};
  grid-template-columns: 1fr 36px;

  border: 1px solid ${({theme}) => theme.border.gray};
`;

export const ErrorText = styled.div`
  color: red;

  height: 14px;
`;

export const IconWrapper = styled.div``;

import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Claim, claimActions, claimSelectors} from 'src/bus/claim';
import {uiSelectors} from 'src/bus/ui';
import {Eusign} from 'src/libs';
import {Routes} from 'src/routes';
import {showToast} from 'src/services/toast';

const SIGN_WIDGET_PARENT_ID = 'esign';
const SIGN_WIDGET_ID = 'sign-widget';
const SIGN_WIDGET_URI = 'https://id.gov.ua/sign-widget/v20200922/';

export const useData = () => {
  const dispatch = useDispatch();

  const detail = useSelector(claimSelectors.getDetailPreview);

  const [files, setFiles] = useState<File[]>([]);

  const [approve, setIsApprove] = useState(false);

  const success = useSelector(claimSelectors.getSuccess);
  const isLoading = useSelector(uiSelectors.getLoading('claim'));

  const navigate = useNavigate();

  const [euSign, setEuSign] = useState<typeof Eusign | null>(null);

  useLayoutEffect(() => {
    const e = new Eusign(
      SIGN_WIDGET_PARENT_ID,
      SIGN_WIDGET_ID,
      SIGN_WIDGET_URI,
      Eusign.FormType.ReadPKey,
      '',
    );
    setEuSign(e);
  }, []);

  const onReadKey = useCallback(async () => {
    try {
      if (euSign && detail) {
        const data: Claim.ReqEsign[] = await euSign.ReadPrivateKey();

        if (data.length) {
          const {
            infoEx: {serial, subjDRFOCode, subjEDRPOUCode, subjFullName},
          } = data[0];

          if (detail.ipn === subjDRFOCode || detail.passport === subjDRFOCode) {
            // dispatch(claimActions.createItemAsync(detail));
            setIsApprove(true);
            showToast({
              message: 'Дані власника ключа та апліканта збігаються!',
              type: 'success',
              position: 'top-center',
              hideProgressBar: true,
              autoClose: 30000,
            });
          } else {
            showToast({
              message:
                'Увага! Дані власника ключа та апліканта не збігаються! Ще раз перевірте коректність заповнення усіх полів. Звертаємо увагу, що Ви маєте підписувати заяву своїм ЕЦП! РНОКПП в заявці та власника ключа мають збігатися.',
              type: 'error',
              position: 'top-center',
              hideProgressBar: true,
              style: {
                width: 700,
                right: '60%',
                // right: 1000,
              },
              autoClose: 30000,
            });
            navigate(Routes.CLAIM_CREATE);
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [euSign, detail]);

  useLayoutEffect(() => {
    onReadKey();
  }, [onReadKey]);

  const onClose = () => {
    if (detail) {
      dispatch(claimActions.createItemAsync(detail));
    }
  };

  useEffect(() => {
    if (success) {
      dispatch(claimActions.toggleSuccess(false));

      navigate(Routes.CLAIM_CREATE, {replace: true});
    }
  }, [success]);

  return {files, setFiles, success: approve, onClose, isLoading};
};

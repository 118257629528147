import {ButtonHTMLAttributes, FC, useMemo} from 'react';
import {TButtonKeys} from 'src/themes/palletes';

import {ButtonWrapper} from './Button.styles';

type TProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  size?: 'normal' | 'large' | 'small' | 'extraSmall';
  variant?: 'round' | 'square' | 'none';
  width?: string;
  margin?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  color?: TButtonKeys;
};

export const Button: FC<TProps> = ({
  width = '300px',
  margin,
  variant,
  color = 'default',
  size = 'normal',
  ...props
}) => {
  const margins = useMemo(
    () => ({
      bottom: margin?.bottom || '0',
      top: margin?.top || '0',
      right: margin?.right || '0',
      left: margin?.left || '0',
    }),
    [margin],
  );
  const borderRadius = useMemo(() => {
    switch (variant) {
      case 'square':
        return '8px';
      case 'round':
        return '16px';
      case 'none':
        return '0px';
      default:
        return '0px';
    }
  }, [variant]);
  const height = useMemo(() => {
    switch (size) {
      case 'extraSmall':
        return '27x';
      case 'small':
        return '32px';
      case 'normal':
        return '48px';
      case 'large':
        return '60px';
      default:
        return '48px';
    }
  }, [variant]);
  return (
    <ButtonWrapper
      margin={margins}
      width={width}
      height={height}
      borderRadius={borderRadius}
      color={color}
      {...props}>
      {props.children}
    </ButtonWrapper>
  );
};

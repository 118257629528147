import React from 'react';
import {Container} from 'src/components';
import {StatisticCard} from 'src/components/Cards/StatisticCard';

import {List, Wrapper, Title} from './ExternalStatistic.styles';
import {useData} from './useData';

export const ExternalStatistic = () => {
  const {statistics} = useData();
  return (
    <Wrapper>
      <Container>
        <Title>Cтатистика</Title>
        <List>
          {statistics.map((statistic) => (
            <StatisticCard
              key={`stat-${statistic.picture}`}
              statistic={statistic}
              onClick={() => {}}
            />
          ))}
        </List>
      </Container>
    </Wrapper>
  );
};

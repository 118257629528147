import {FontWeight} from 'src/themes';
import styled from 'styled-components';

type TProps = {
  blue?: boolean;
};

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 16px;

  ${({theme}) => theme.mediaQueriesMax.sm} {
    grid-template-columns: 1fr;
  }
`;
export const FieldWrapper = styled.div``;

export const Label = styled.p<TProps>`
  color: ${({theme, blue = true}) =>
    blue ? theme.text.dark : theme.text.black};
  margin: 24px 0 16px 0;
  font-size: 15px;
  display: block;
  font-weight: ${FontWeight.light};
  & > span {
    color: red;
  }
`;

export const ErrorText = styled.div`
  color: red;
`;

import {AxiosPromise} from 'axios';
import axios from 'src/services/axios';
import {Claim} from './namespace';

import qs from 'qs';
import {removeNull} from 'src/helpers';

export const apiClaim = new (class Api {
  createItem(obj: Claim.ReqCreateItem): AxiosPromise {
    const fd = new FormData();
    const data = removeNull(obj);
    for (let key in data) {
      if (key === 'tel') {
        //@ts-ignore
        fd.append(`claim[${key}]`, data[key].slice(1));
        continue;
      }

      if (
        key === 'citizen_document' ||
        key === 'employed_document' ||
        key === 'army_costs_document' ||
        key === 'internally_document' ||
        key === 'actual_costs_document' ||
        key === 'bank_account_document'
      ) {
        //@ts-ignore
        data[key].forEach((item, index) => {
          fd.append(`claim[${key}][]`, item);
        });

        continue;
      }

      if (key === 'tel') {
        //@ts-ignore

        fd.append(`claim[${key}]`, data[key].replace(/\D/g, '').slice(3));
        continue;
      }

      if (key === 'actual_address') {
        fd.append(
          `actual_address[address_id]`,
          //@ts-ignore

          data.actual_address?.address_id?.toString() || '',
        );
        fd.append(
          `actual_address[apartment]`,
          //@ts-ignore
          data.actual_address.apartment.toString(),
        );
        fd.append(
          `actual_address[house]`,
          //@ts-ignore
          data.actual_address.house.toString(),
        );
        fd.append(
          `actual_address[street]`,
          //@ts-ignore
          data.actual_address.street.toString(),
        );
        fd.append(
          `actual_address[zip_code]`,
          //@ts-ignore
          data.actual_address.zip_code.toString(),
        );

        continue;
      }
      if (key === 'registration_address') {
        fd.append(
          `registration_address[address_id]`,
          //@ts-ignore
          data.registration_address?.address_id?.toString() || '',
        );
        fd.append(
          `registration_address[apartment]`,
          //@ts-ignore
          data.registration_address.apartment.toString(),
        );
        fd.append(
          `registration_address[house]]`,
          //@ts-ignore
          data.registration_address.house.toString(),
        );
        fd.append(
          `registration_address[street]`,
          //@ts-ignore
          data.registration_address.street.toString(),
        );
        fd.append(
          `registration_address[zip_code]`,
          //@ts-ignore
          data.registration_address.zip_code.toString(),
        );

        continue;
      }

      //@ts-ignore
      fd.append(`claim[${key}]`, data[key]);
    }

    return axios({
      url: '/claims',
      method: 'post',
      data: fd,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  fetchItems(params: Claim.ReqFetchItems): AxiosPromise<Claim.ResFetchItems> {
    return axios({
      url: '/claims',
      method: 'get',
      params: {
        ...params,
        filter: removeNull(params.filter),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }

  fetchDetail(id: number): AxiosPromise<Claim.ResFetchDeail> {
    return axios({
      url: `/claims/${id}`,
      method: 'get',
    });
  }

  updateItem(data: Claim.ReqUpdateItem): AxiosPromise<Claim.ResUpdateItem> {
    const newData =
      data.selected !== undefined
        ? {
            claim: {
              selected: data.selected,
            },
          }
        : {
            claim: {
              status: data.status,
            },
          };

    return axios({
      url: `/claims/${data.id}`,
      method: 'put',

      data: newData,
    });
  }

  sendMail(data: Claim.ReqSendMail): AxiosPromise {
    return axios({
      url: `/claims/${data.id}/sent_mail`,
      method: 'post',

      data: {
        note: data.note,
        mail: data.mail,
      },
    });
  }

  updateReason(data: Claim.ReqUpdateReason): AxiosPromise {
    return axios({
      url: `/claims/${data.id}/moderator_column`,
      method: 'post',
      data: {
        moderator_message: data.moderator_message,
      },
    });
  }

  fetchPreviewItem(id: number): AxiosPromise<Claim.Detail> {
    return axios({
      url: `/claim/${id}/pdf`,
      method: 'get',
    });
  }
})();

import React, {FC, useEffect} from 'react';
import {Location} from 'src/bus/location';
import {Autocomplete} from 'src/components/Core';
import {ErrorText, FieldWrapper, Label, Wrapper} from './AddressForm.styles';
import {useData} from './useData';

type TProps = {
  onSelect: (address: Location.ItemCustom) => any;
  addressOld: Location.ItemCustom;
  name: Location.AddressNames;
  error: boolean;
};

const check = (
  current: Location.AddressTypes | '',
  name: Location.AddressTypes,
) => {
  if (!current) return false;

  if (current === 'region_name') {
    return name === 'region_name';
  }
  if (current === 'district_name') {
    return name === 'region_name' || name === 'district_name';
  }
  if (current === 'community') {
    return (
      name === 'region_name' || name === 'district_name' || name === 'community'
    );
  }

  return true;
};

export const AddressForm: FC<TProps> = ({
  addressOld,
  onSelect,
  name,
  error,
}) => {
  const {
    address,
    community,
    districts,
    fullName,
    regions,
    type,
    onSelectItem,
    id,
  } = useData(addressOld, name);

  useEffect(() => {
    if (type === 'full_name' && id) {
      onSelect({
        ...address,
        id: id,
      });
    } else {
      onSelect({});
    }
  }, [type, id]);

  return (
    <>
      <Wrapper>
        <FieldWrapper>
          <Label>
            Область<span>*</span>
          </Label>
          <Autocomplete
            name={new Date().getMilliseconds().toString()}
            onSelect={(value) => onSelectItem({type: 'region_name', value})}
            width="100%"
            items={regions}
            initValue={address.region_name || ''}
          />
        </FieldWrapper>
        {check(type, 'region_name') &&
          !!districts.length &&
          address?.region_name && (
            <>
              <FieldWrapper>
                <Label>
                  Район<span>*</span>
                </Label>

                <Autocomplete
                  name={new Date().getMilliseconds().toString()}
                  onSelect={(value) =>
                    onSelectItem({
                      type: 'district_name',
                      value,
                    })
                  }
                  initValue={address.district_name || ''}
                  width="100%"
                  items={districts}
                />
              </FieldWrapper>
            </>
          )}

        {check(type, 'district_name') &&
          !!community.length &&
          address?.district_name && (
            <FieldWrapper>
              <Label>
                ОТГ<span>*</span>
              </Label>
              <Autocomplete
                name={new Date().getMilliseconds().toString()}
                onSelect={(value) => onSelectItem({type: 'community', value})}
                initValue={address.community || ''}
                width="100%"
                items={community}
              />
            </FieldWrapper>
          )}

        {check(type, 'community') && !!fullName.length && address?.community && (
          <FieldWrapper>
            <Label>
              Населенний пункт<span>*</span>
            </Label>
            <Autocomplete
              name={new Date().getMilliseconds().toString()}
              onSelect={(value) => onSelectItem({type: 'full_name', value})}
              initValue={address.full_name || ''}
              width="100%"
              items={fullName}
            />
          </FieldWrapper>
        )}
      </Wrapper>
      <ErrorText>{error && 'Заповніть адресу коректними данними'}</ErrorText>
    </>
  );
};

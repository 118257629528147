import React, {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {
  Checkbox,
  Container,
  DateField,
  FilledField,
  MaskedField,
  AreaField,
  FileUploader,
  Button,
  SelectBox,
  AddressForm,
} from 'src/components';

import {
  CategoryLabel,
  CategoryMainLabel,
  CategoryWrapper,
  CheckboxLabel,
  CheckboxWrapper,
  Content,
  FieldWrapper,
  Footer,
  HalfWrapper,
  Label,
  Section,
  Title,
  Uploaders,
  Wrapper,
  BottomCheckboxes,
} from './ClaimCreate.styles';
import {useData} from './useData';
import {format} from 'date-fns';
export const ClaimCreate = () => {
  const {
    control,
    errors,
    handleSubmit,
    onChangeCategory,
    onChangeSex,
    category,
    onChangeEconomicActivity,
    economicActivity,
    onChangeImage,
    actual_costs_documents,
    army_documents,
    internally_documents,
    citizen_documents,
    employed_documents,
    registration_address,
    actual_address,
    bank_account_document,
    sex,
    c1,
    c2,
    c3,
    c4,
    onChange,

    onChangeActualAddress,
    onChangeRegistationAddress,

    isPassport,
    onChangeIsPassport,
  } = useData();
  const DAY_MILISECONDS = 31536000000 * 18 + 86400000 * 4;
  const max = useMemo(() => {
    const date = new Date();

    const now = date.getMilliseconds();

    const next = now - DAY_MILISECONDS;

    return date.setMilliseconds(next);
  }, []);

  return (
    <Wrapper>
      <Container>
        <Content onSubmit={handleSubmit}>
          <Title>Аплікаційна заявка</Title>
          <Section>
            <Label>E-mail для контактів – veteranfundua@gmail.com</Label>

            <Label>
              <span> Поля з зірочкою – обов’язкові для заповнення </span>
            </Label>
            <Label>
              Прізвище, ім'я та по батькові <span>*</span>
            </Label>
            <Controller
              control={control}
              name="name"
              render={({field: {name, onBlur, onChange, value}}) => (
                <FilledField
                  width="100%"
                  placeholder="ПІБ"
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  error={errors[name]?.message}
                />
              )}
            />

            <HalfWrapper>
              <FieldWrapper>
                <Label>
                  Стать<span>*</span>
                </Label>

                <Controller
                  control={control}
                  name="sex"
                  render={({field: {name, value, onBlur, onChange}}) => (
                    <SelectBox
                      onChange={(e) => {
                        onChange({
                          ...e,
                          target: {...e.target, value: +e.target.value},
                        });
                        onChangeSex(!!+e.target.value);
                      }}
                      width="100%">
                      <option value={1}>Чоловік</option>
                      <option value={0}>Жінка</option>
                    </SelectBox>
                  )}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Label>
                  Дата народження<span>*</span>
                </Label>

                <Controller
                  control={control}
                  name="birthday"
                  render={({field: {name, value, onBlur, onChange}}) => (
                    <DateField
                      placeholder="Дата"
                      onChange={onChange}
                      onBlur={onBlur}
                      max={format(max, 'yyyy-LL-dd')}
                      value={value}
                      name={name}
                      error={errors[name]?.message}
                    />
                  )}
                />
              </FieldWrapper>
            </HalfWrapper>

            {!isPassport && (
              <>
                <Label>
                  Реєстраційний номер облікової картки платника податків
                  (РНОКПП)
                  <span>*</span>
                </Label>

                <Controller
                  control={control}
                  name="ipn"
                  render={({field: {name, onBlur, onChange, value}}) => (
                    <FilledField
                      name={name}
                      onBlur={onBlur}
                      width="100%"
                      onChange={(e) =>
                        onChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: e.target.value
                              .replace(/\s/g, '')
                              .toUpperCase(),
                          },
                        })
                      }
                      value={value}
                      placeholder="0102030405"
                      error={errors[name]?.message}
                    />
                  )}
                />
              </>
            )}

            {isPassport && (
              <>
                {' '}
                <Label>
                  АБО серія та номер паспорта для осіб, які відмовились від
                  присвоєння РНОКПП через релігійні переконання
                  <span>*</span>
                </Label>
                <Controller
                  control={control}
                  name="passport"
                  render={({field: {name, onBlur, onChange, value}}) => (
                    <FilledField
                      name={name}
                      onBlur={onBlur}
                      width="100%"
                      onChange={(e) =>
                        onChange({
                          ...e,
                          target: {
                            ...e.target,
                            value: e.target.value
                              .replace(/\s/g, '')
                              .toUpperCase(),
                          },
                        })
                      }
                      value={value}
                      placeholder="AA748596"
                      error={errors[name]?.message}
                    />
                  )}
                />
              </>
            )}
            <CheckboxWrapper role="button" padding="20px">
              <Checkbox
                value={isPassport}
                onChange={() => onChangeIsPassport(!isPassport)}
              />
              <CheckboxLabel onClick={() => onChangeIsPassport(!isPassport)}>
                Або серія та номер паспорта для осіб, які відмовились від
                присвоєння РНОКПП через релігійні переконання
              </CheckboxLabel>
            </CheckboxWrapper>

            <Label>Адреса реєстрації:</Label>

            <AddressForm
              addressOld={registration_address}
              name="registration"
              onSelect={(data) => {
                onChangeRegistationAddress(data);
              }}
              error={!!errors.registration_address?.id}
            />

            {!!registration_address.id && (
              <>
                <FieldWrapper>
                  <Label>
                    Назва вулиці<span>*</span>
                  </Label>
                  <Controller
                    control={control}
                    name="reg_address.street"
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <FilledField
                        name={name}
                        onBlur={onBlur}
                        width="100%"
                        onChange={onChange}
                        value={value}
                        placeholder="Введіть будь-ласка назву вулиці"
                        error={errors['reg_address']?.street?.message}
                      />
                    )}
                  />
                </FieldWrapper>
                <HalfWrapper>
                  <FieldWrapper>
                    <Label>
                      Номер будинку<span>*</span>
                    </Label>
                    <Controller
                      control={control}
                      name="reg_address.house"
                      render={({field: {name, onBlur, onChange, value}}) => (
                        <FilledField
                          name={name}
                          onBlur={onBlur}
                          width="100%"
                          onChange={onChange}
                          value={value}
                          placeholder="Введіть, будь ласка, номер будинку"
                          error={errors['reg_address']?.house?.message}
                        />
                      )}
                    />
                  </FieldWrapper>

                  <FieldWrapper>
                    <Label>Номер квартири</Label>
                    <Controller
                      control={control}
                      name="reg_address.apartment"
                      render={({field: {name, onBlur, onChange, value}}) => (
                        <FilledField
                          name={name}
                          onBlur={onBlur}
                          width="100%"
                          onChange={onChange}
                          value={value}
                          placeholder="Введіть, будь ласка, номер квартири"
                          error={errors['reg_address']?.apartment?.message}
                        />
                      )}
                    />
                  </FieldWrapper>
                </HalfWrapper>
                <FieldWrapper>
                  <Label>
                    Індекс<span>*</span>
                  </Label>
                  <Controller
                    control={control}
                    name="reg_address.zip_code"
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <MaskedField
                        name={name}
                        onBlur={onBlur}
                        width="100%"
                        onChange={onChange}
                        value={value}
                        placeholder="Введіть, будь ласка, індекс"
                        error={errors['reg_address']?.zip_code?.message}
                        mask="index"
                      />
                    )}
                  />
                </FieldWrapper>
              </>
            )}

            <Label>Адреса фактичного перебування: </Label>

            <AddressForm
              addressOld={actual_address}
              name="actual"
              onSelect={(data) => {
                onChangeActualAddress(data);
              }}
              error={!!errors.actual_address?.id}
            />

            {!!actual_address.id && (
              <>
                <FieldWrapper>
                  <Label>
                    Назва вулиці<span>*</span>
                  </Label>
                  <Controller
                    control={control}
                    name="act_address.street"
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <FilledField
                        name={name}
                        onBlur={onBlur}
                        width="100%"
                        onChange={onChange}
                        value={value}
                        placeholder="Введіть будь-ласка назву вулиці"
                        error={errors['act_address']?.street?.message}
                      />
                    )}
                  />
                </FieldWrapper>
                <HalfWrapper>
                  <FieldWrapper>
                    <Label>
                      Номер будинку<span>*</span>
                    </Label>
                    <Controller
                      control={control}
                      name="act_address.house"
                      render={({field: {name, onBlur, onChange, value}}) => (
                        <FilledField
                          name={name}
                          onBlur={onBlur}
                          width="100%"
                          onChange={onChange}
                          value={value}
                          placeholder="Введіть, будь ласка, номер будинку"
                          error={errors['act_address']?.house?.message}
                        />
                      )}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <Label>Номер квартири</Label>
                    <Controller
                      control={control}
                      name="act_address.apartment"
                      render={({field: {name, onBlur, onChange, value}}) => (
                        <FilledField
                          name={name}
                          onBlur={onBlur}
                          width="100%"
                          onChange={onChange}
                          value={value}
                          placeholder="Введіть, будь ласка, номер квартири"
                          error={errors['act_address']?.apartment?.message}
                        />
                      )}
                    />
                  </FieldWrapper>
                </HalfWrapper>
                <FieldWrapper>
                  <Label>
                    Індекс<span>*</span>
                  </Label>
                  <Controller
                    control={control}
                    name="act_address.zip_code"
                    render={({field: {name, onBlur, onChange, value}}) => (
                      <MaskedField
                        name={name}
                        onBlur={onBlur}
                        width="100%"
                        onChange={onChange}
                        value={value}
                        placeholder="Введіть, будь ласка, індекс"
                        error={errors['act_address']?.zip_code?.message}
                        mask="index"
                      />
                    )}
                  />
                </FieldWrapper>
              </>
            )}

            <Label>
              Форма здійснення господарської діяльності<span>*</span>
            </Label>

            <HalfWrapper>
              <CheckboxWrapper
                role="button"
                onClick={() => onChangeEconomicActivity(true)}>
                <Checkbox
                  error={!!errors['economic_activity']?.message}
                  value={economicActivity === null ? false : economicActivity}
                  onChange={() => onChangeEconomicActivity(true)}
                />
                <CheckboxLabel>Фізична особа-підприємець</CheckboxLabel>
              </CheckboxWrapper>
              <CheckboxWrapper
                role="button"
                onClick={() => onChangeEconomicActivity(false)}>
                <Checkbox
                  error={!!errors['economic_activity']?.message}
                  value={economicActivity === null ? false : !economicActivity}
                  onChange={() => onChangeEconomicActivity(false)}
                />
                <CheckboxLabel>Інша категорія самозайнятої особи</CheckboxLabel>
              </CheckboxWrapper>
            </HalfWrapper>
            <FieldWrapper>
              <Label>
                Опис здійснюваної господарської діяльності<span>*</span>:
              </Label>

              <Controller
                control={control}
                name="economic_description"
                render={({field: {name, onBlur, onChange, value}}) => (
                  <AreaField
                    value={value}
                    name={name}
                    type="text"
                    onBlur={onBlur}
                    width="100%"
                    height="80px"
                    onChange={onChange}
                    error={errors[name]?.message}
                    placeholder="Виробництво меблів для офісів і підприємств"
                  />
                )}
              />
            </FieldWrapper>
            <HalfWrapper>
              <FieldWrapper>
                <Label>
                  Назва банку<span>*</span>
                </Label>

                <Controller
                  control={control}
                  name="bank_name"
                  render={({field: {name, value, onBlur, onChange}}) => (
                    <FilledField
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      width="100%"
                      name={name}
                      placeholder="Приватбанк"
                      error={errors[name]?.message}
                    />
                  )}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Label>
                  Рахунок у форматі IBAN<span>*</span>
                </Label>

                <Controller
                  control={control}
                  name="iban"
                  render={({field: {name, value, onBlur, onChange}}) => (
                    <MaskedField
                      name={name}
                      onBlur={onBlur}
                      width="100%"
                      onChange={(e) =>
                        onChange({
                          target: {value: e.target.value.toUpperCase()},
                        })
                      }
                      value={value}
                      error={errors[name]?.message}
                      placeholder="UA45667878900000876543322"
                      mask="iban"
                    />
                  )}
                />
              </FieldWrapper>
            </HalfWrapper>

            <Label>
              Для фізичного листування<span>*</span>
            </Label>
            <Controller
              control={control}
              name="correspondence_address"
              render={({field: {name, value, onBlur, onChange}}) => (
                <FilledField
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  width="100%"
                  placeholder="м. Київ, вул. Пилипська, 1, кв. 1, 01001"
                  error={errors[name]?.message}
                />
              )}
            />

            <HalfWrapper>
              <FieldWrapper>
                <Label>
                  E-mail<span>*</span>
                </Label>

                <Controller
                  control={control}
                  name="email"
                  render={({field: {name, value, onBlur, onChange}}) => (
                    <FilledField
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      width="100%"
                      placeholder="pylypenko12345@gmail.com"
                      error={errors[name]?.message}
                    />
                  )}
                />
              </FieldWrapper>

              <FieldWrapper>
                <Label>
                  Повторіть, будь ласка, E-mail <span>*</span>
                </Label>

                <Controller
                  control={control}
                  name="confirm_email"
                  render={({field: {name, value, onBlur, onChange}}) => (
                    <FilledField
                      onChange={onChange}
                      onBlur={onBlur}
                      value={value}
                      name={name}
                      width="100%"
                      placeholder="pylypenko12345@gmail.com"
                      error={errors[name]?.message}
                    />
                  )}
                />
              </FieldWrapper>
            </HalfWrapper>

            <Label>
              Контактний телефон<span>*</span>
            </Label>
            <Controller
              control={control}
              name="tel"
              render={({field: {name, onBlur, onChange, value}}) => (
                <MaskedField
                  name={name}
                  onBlur={onBlur}
                  width="100%"
                  mask="phone"
                  onChange={(e) => {
                    onChange({
                      target: {
                        value: e.target.value.replace(/\D/g, ''),
                      },
                    });
                  }}
                  value={value}
                  error={errors[name]?.message}
                  placeholder={'(___)___ __ __'}
                />
              )}
            />
          </Section>

          <Section>
            <Label>
              До якої категорії осіб Ви відноситесь<span>*</span>
            </Label>

            <CategoryWrapper
              role="button"
              onClick={() => onChangeCategory(true)}>
              <Checkbox
                value={category === null ? false : category}
                onChange={() => onChangeCategory(true)}
                error={!!errors['category']?.message}
              />
              <CategoryLabel>
                <CategoryMainLabel>Категорія А</CategoryMainLabel>
                Члени сімей ветеранів, які беруть участь у відсічі збройної
                агресії та забезпеченні національної безпеки, усуненні загрози
                небезпеки державній незалежності України, її територіальній
                цілісності, у тому числі у складі добровольчих формувань
                територіальних громад, у період дії воєнного стану
              </CategoryLabel>
            </CategoryWrapper>

            <CategoryWrapper
              role="button"
              onClick={() => onChangeCategory(false)}>
              <Checkbox
                value={category === null ? false : !category}
                onChange={() => onChangeCategory(false)}
                error={!!errors['category']?.message}
              />
              <CategoryLabel>
                <CategoryMainLabel>Категорія Б</CategoryMainLabel>
                Ветерани та члени їхніх сімей, діяльність яких як самозайнятих
                осіб тимчасово зупинена внаслідок проведення бойових дій на
                території адміністративно-територіальних одиниць, перелік яких
                затверджений Кабінетом Міністрів України
              </CategoryLabel>
            </CategoryWrapper>
            <FieldWrapper>
              <Label>
                Вкажіть родинні зв'язки або зазначте, що Ви самі є ветераном{' '}
                <span>*</span>:{' '}
              </Label>

              <Controller
                control={control}
                name="family_ties"
                render={({field: {name, onBlur, onChange, value}}) => (
                  <AreaField
                    value={value}
                    name={name}
                    type="text"
                    onBlur={onBlur}
                    width="100%"
                    height="140px"
                    onChange={onChange}
                    placeholder="До членів сімей ветеранів відповідно до ст. 1 Закону України «Про запобігання корупції» належать:
1) особи, які перебувають у шлюбі із ветераном, та діти ветерана до досягнення ними повноліття – незалежно від спільного проживання із ветераном;
2) будь-які особи, які спільно проживають, пов’язані спільним побутом, мають взаємні права та обов’язки із ветераном (крім осіб, взаємні права та обов’язки яких не мають характеру сімейних), у тому числі особи, які спільно проживають, але не перебувають у шлюбі.
"
                    error={errors[name]?.message}
                  />
                )}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Label>
                Прізвище, ім'я та по батькові власника посвідчення Учасника
                бойових дій<span>*</span>
              </Label>

              <Controller
                control={control}
                name="full_name_participant_hostilities"
                render={({field: {name, onBlur, onChange, value}}) => (
                  <FilledField
                    width="100%"
                    placeholder="ПІБ"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={errors[name]?.message}
                  />
                )}
              />
            </FieldWrapper>

            <FieldWrapper>
              <Label>
                Cерія та номера посвідчення Учасника бойових дій<span>*</span>
              </Label>

              <Controller
                control={control}
                name="certificate_participant_hostilities"
                render={({field: {name, onBlur, onChange, value}}) => (
                  <FilledField
                    width="100%"
                    placeholder="УБД000000"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    error={errors[name]?.message}
                  />
                )}
              />
            </FieldWrapper>

            <Label>
              На що саме потрібні кошти<span>*</span>
            </Label>
            <Controller
              control={control}
              name="description"
              render={({field: {name, onBlur, onChange, value}}) => (
                <AreaField
                  value={value}
                  name={name}
                  type="text"
                  onBlur={onBlur}
                  width="100%"
                  height="80px"
                  onChange={onChange}
                  placeholder="Купівля інструментів для ведення перукарської діяльності"
                  error={errors[name]?.message}
                />
              )}
            />

            <Label>
              Сума коштів<span>*</span>
            </Label>
            <Controller
              control={control}
              name="price"
              render={({field: {name, onBlur, onChange, value}}) => (
                <FilledField
                  value={value}
                  name={name}
                  type="text"
                  onBlur={onBlur}
                  width="100%"
                  onChange={onChange}
                  placeholder="20 000"
                  error={errors[name]?.message}
                />
              )}
            />
          </Section>

          <Uploaders>
            <Label>
              <b>Завантажити скани/фото документів</b>
            </Label>
            <Label blue={false}>
              Документ, що посвідчує особу громадянина України: <span>*</span>
            </Label>
            <Controller
              control={control}
              name="citizen_documents"
              render={({field: {name}}) => (
                <FileUploader
                  files={citizen_documents}
                  onChangeFiles={(files) => onChangeImage(files, name)}
                  error={
                    //@ts-ignore
                    errors[name]?.message
                  }
                />
              )}
            />
            <Label blue={false}>
              Документ про набуття статусу підприємця фізичною особою (для
              фізичних осіб- підприємців) АБО Документ про взяття на облік
              самозайнятої особи: <span>*</span>
            </Label>
            <Controller
              control={control}
              name="employed_documents"
              render={({field: {name}}) => (
                <FileUploader
                  files={employed_documents}
                  onChangeFiles={(files) => onChangeImage(files, name)}
                  error={
                    //@ts-ignore
                    errors[name]?.message
                  }
                />
              )}
            />
            <Label blue={false}>
              Документ, що підтверджує один із статусів ветерана війни, особи,
              яка має особливі заслуги перед Батьківщиною, постраждалого
              учасника Революції Гідності, члена сім’ї загиблого (померлого)
              Захисника чи Захисниці України або належність до членів сім’ї
              ветерана: <span>*</span>
            </Label>
            <Controller
              control={control}
              name="army_documents"
              render={({field: {name}}) => (
                <FileUploader
                  files={army_documents}
                  onChangeFiles={(files) => onChangeImage(files, name)}
                  error={
                    //@ts-ignore
                    errors[name]?.message
                  }
                />
              )}
            />

            <Label blue={false}>
              Документ, що підтверджує взяття особи на облік внутрішньо
              переміщеної особи: <span>*</span>
            </Label>
            <Controller
              control={control}
              name="internally_documents"
              render={({field: {name}}) => (
                <FileUploader
                  files={internally_documents}
                  onChangeFiles={(files) => onChangeImage(files, name)}
                  error={
                    //@ts-ignore
                    errors[name]?.message
                  }
                />
              )}
            />

            <Label blue={false}>
              Документ, про понесення фактичних витрат, пов’язаних із
              здійсненням підприємницької або незалежної професійної діяльності
              самозайнятою особою, які можуть бути відшкодовані за рахунок
              Фонду: <span>*</span>
            </Label>
            <Controller
              control={control}
              name="actual_costs_documents"
              render={({field: {name}}) => (
                <FileUploader
                  files={actual_costs_documents}
                  onChangeFiles={(files) => onChangeImage(files, name)}
                  error={
                    //@ts-ignore
                    errors[name]?.message
                  }
                />
              )}
            />

            <Label blue={false}>
              З метою уникнення непорозумінь при перерахуванні коштів просимо
              додатково завантажити довідку про відкриття Вашого банківського
              рахунку з Вашими актуальними банківськими реквізитами:
            </Label>
            <Controller
              control={control}
              name="bank_account_document"
              render={({field: {name}}) => (
                <FileUploader
                  files={bank_account_document}
                  onChangeFiles={(files) => onChangeImage(files, name)}
                  error={
                    //@ts-ignore
                    errors[name]?.message
                  }
                />
              )}
            />
          </Uploaders>
          <BottomCheckboxes>
            <CheckboxWrapper role="button">
              <Checkbox
                value={c1}
                onChange={() => onChange('c1', !c1)}
                error={!!errors['c1']?.message}
              />
              <CheckboxLabel onClick={() => onChange('c1', !c1)}>
                Поданням заяви ви засвідчуєте, що{' '}
                <a
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                  href="https://20000.mva.gov.ua/poryadok.pdf"
                  target="_blank"
                  rel="noreferrer">
                  ознайомленні з приписами{' '}
                </a>
                «Порядку використання коштів бюджетною установою «Український
                ветеранський фонд» у період дії воєнного стану»
              </CheckboxLabel>
            </CheckboxWrapper>
            <CheckboxWrapper role="button">
              <Checkbox
                value={c2}
                onChange={() => onChange('c2', !c2)}
                error={!!errors['c2']?.message}
              />
              <CheckboxLabel onClick={() => onChange('c2', !c2)}>
                Поданням заяви ви засвідчуєте достовірність поданих документів
              </CheckboxLabel>
            </CheckboxWrapper>
            <CheckboxWrapper role="button">
              <Checkbox
                value={c3}
                onChange={() => onChange('c3', !c3)}
                error={!!errors['c3']?.message}
              />
              <CheckboxLabel onClick={() => onChange('c3', !c3)}>
                Поданням заяви ви надаєте УВФ згоду проведення фото та відео
                фіксації вас та процесу вашої господарської діяльності з метою
                підготовки УВФ звіту про виділені кошти.
              </CheckboxLabel>
            </CheckboxWrapper>
            <CheckboxWrapper role="button">
              <Checkbox
                value={c4}
                onChange={() => onChange('c4', !c4)}
                error={!!errors['c4']?.message}
              />
              <CheckboxLabel onClick={() => onChange('c4', !c4)}>
                Поданням заяви ви надаєте УВФ свою згоду на обробку ваших
                персональних даних.
              </CheckboxLabel>
            </CheckboxWrapper>
          </BottomCheckboxes>
          <Footer>
            <Button width="100%">РЕЄСТРАЦІЯ</Button>
          </Footer>
        </Content>
      </Container>
    </Wrapper>
  );
};

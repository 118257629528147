import {AxiosResponse} from 'axios';
import {all, put, call} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {FetchItemsAsync} from '../../types';
import {apiStatistic} from '../../api';
import {Statistic} from '../../namespace';
import {statisticActions} from '../../slice';

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'statistic', loading: true}));
    const response: AxiosResponse<Statistic.ResFetchItems> = yield call(
      apiStatistic.fetchItems,
      action.payload,
    );

    if (response.data) {
      yield put(statisticActions.saveItems(response.data));
    }
  } catch (e) {
    console.log(`error fetch statistic items worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'statistic', loading: false}));
  }
}

import styled from 'styled-components';
type TWrapperProps = {
  margin?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  square?: boolean;

  error: boolean;
};
export const Wrapper = styled.button<TWrapperProps>`
  justify-content: center;
  align-items: center;

  background-color: ${({theme}) => theme.background.default};

  cursor: pointer;

  border: 1px solid
    ${({error, theme}) => theme.border[error ? 'error' : 'gray']};

  outline: none;

  border-radius: ${({theme, square = true}) => (square ? ' 0px' : '100%')};

  width: 28px;
  height: 28px;

  ${({margin}) => `
    margin-top: ${margin?.top || 0}; 
    margin-bottom: ${margin?.bottom || 0};
    margin-right: ${margin?.right || 0};
    margin-left: ${margin?.left || 0};
  `};
`;

import {locationActions} from 'src/bus/location';
import {AxiosResponse} from 'axios';
import {all, put, call} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {FetchItemsAsync} from '../../types';
import {apiLocation} from '../../api';
import {Location} from '../../namespace';

export function* fetchItems(action: FetchItemsAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'location', loading: true}));

    yield put(
      locationActions.saveAddress({
        items: [],
        name: action.payload.key,
        type: action.payload.type,
      }),
    );

    const response: AxiosResponse<Location.ResFetchItems> = yield call(
      apiLocation.fetchItems,
      action.payload,
    );

    if (response.data) {
      yield put(
        locationActions.saveAddress({
          items: response.data,
          name: action.payload.key,
          type: action.payload.type,
        }),
      );
    }
  } catch (e) {
    console.log(`error fetch location items worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'location', loading: false}));
  }
}

export enum Routes {
  CLAIM_CREATE = '/',

  EMPTY = '/final',

  CLAIM_PREVIEW = '/claim-preview',

  EXTERNAL = '/statistics',

  ESIGN = '/esign',
}

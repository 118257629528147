import {createContext} from 'react';

//themes
import {Pallete} from './palletes';
import * as palletes from './palletes';

export enum ThemesName {
  LIGHT = 'light',
}

export enum FontWeight {
  extraLight = '400',
  light = '500',
  regular = '600',
  medium = '700',
  semiBold = '800',
  bold = '900',
  extraBold = '1000',
}

//   regular = 'Play-Regular',
//   bold = 'Play-Bold',

type ThemesItem = {
  pallete: Pallete;
  name: ThemesName;
  fonts: typeof FontWeight;
};

type Themes = {
  light: ThemesItem;
};

export const themes: Themes = {
  light: {
    pallete: palletes[ThemesName.LIGHT],
    name: ThemesName.LIGHT,
    fonts: FontWeight,
  },
};

export const ThemeContext = createContext(themes[ThemesName.LIGHT]);

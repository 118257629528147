import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {appActions, appSelectors} from 'src/bus/app';
import {claimSelectors} from 'src/bus/claim';
import {uiSelectors} from 'src/bus/ui';
import {
  ClaimCreate,
  Empty,
  ClaimPreview,
  ExternalStatistic,
  ESignPage,
} from 'src/pages';

import {Routes as RouteNames} from './';

export const AppRouter = () => {
  const dispatch = useDispatch();
  const initialize = useSelector(appSelectors.getInitialized);

  const link = useSelector(uiSelectors.getOpenLink);

  const navigate = useNavigate();

  const success = useSelector(claimSelectors.getSuccess);

  useEffect(() => {
    if (link) {
      navigate(link);
    }
  }, [link]);

  if (success) {
    return (
      <Routes>
        <Route path={RouteNames.CLAIM_PREVIEW} element={<Empty />} />
      </Routes>
    );
  }

  return (
    <>
      <Routes>
        <Route path={RouteNames.CLAIM_PREVIEW} element={<ClaimPreview />} />
        <Route path={RouteNames.CLAIM_CREATE} element={<ClaimCreate />} />
        <Route path={RouteNames.EMPTY} element={<Empty />} />

        <Route path={RouteNames.EXTERNAL} element={<ExternalStatistic />} />
        <Route path={RouteNames.ESIGN} element={<ESignPage />} />
      </Routes>
    </>
  );
};

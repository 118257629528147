import {Claim} from './namespace';

export enum types {
  CREATE_ITEM = 'CLAIM/CREATE_ITEM',
}

export type ClaimState = {
  detail: Claim.Detail | null;
  items: Claim.Item[];

  detail_preview: Claim.ReqCreateItem | null;

  sorter: Claim.Sorter | null;
  filter: Claim.Filter | null;

  current_page: number;
  total_page: number;
  count: number;

  success: boolean;
};

export type CreateItemAsync = {
  type: typeof types.CREATE_ITEM;
  payload: Claim.ReqCreateItem;
};

export type ClaimActionTypes = CreateItemAsync;

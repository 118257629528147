import styled from 'styled-components';
import {FontWeight} from 'src/themes';
import {TButtonKeys} from 'src/themes/palletes';

type TProps = {
  width: string;
  height: string;

  margin: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
  borderRadius: string;

  color: TButtonKeys;
};
export const ButtonWrapper = styled.button<TProps>`
  height: ${({height}) => height};
  width: ${({width}) => width};
  align-items: center;

  background-color: ${({theme, color}) => theme.button.background[color]};
  color: ${({theme, color}) => theme.button.text[color]};

  font-size: 15px;
  font-weight: ${FontWeight.light};
  border-radius: ${({borderRadius}) => borderRadius};
  cursor: pointer;

  border: 1px solid ${({theme, color}) => theme.button.border[color]};

  cursor: pointer;

  ${({margin}) => `
    margin-top: ${margin.top};
    margin-bottom: ${margin.bottom};
    margin-right: ${margin.right};
    margin-left: ${margin.left};
  `};
`;

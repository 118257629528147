import {AxiosPromise} from 'axios';
import {removeNull} from 'src/helpers';
import axios from 'src/services/axios';
import {Location} from './namespace';

export const apiLocation = new (class Api {
  fetchItems({
    params,
  }: Location.ReqFetchItems): AxiosPromise<Location.ResFetchItems> {
    return axios({
      url: '/addresses',
      method: 'GET',
      params: removeNull(params),
    });
  }
})();

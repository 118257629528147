import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 0 15px;

  ${({theme}) => theme.mediaQueries.xs} {
    padding: 0 calc(50vw - 270px);
  }
  ${({theme}) => theme.mediaQueries.sm} {
    padding: 0 calc(50vw - 360px);
  }
  ${({theme}) => theme.mediaQueries.md} {
    padding: 0 calc(50vw - 480px);
  }

  ${({theme}) => theme.mediaQueries.lg} {
    padding: 0 calc(50vw - 590px);
  }
`;

import {Statistic} from './namespace';

export enum types {
  FETCH_ITEMS = 'STATISTIC/FETCH_ITEMS',
}

export type StatisticState = {
  items: Statistic.Item[];
};

export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS;
  payload: Statistic.ReqFetchItems;
};

export type StatisticActionTypes = FetchItemsAsync;

import styled from 'styled-components';
import {FontWeight} from 'src/themes';

export const Wrapper = styled.div`
  width: 100%;

  height: 58px;

  border: 1px dashed ${({theme}) => theme.border.gray};

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 15px;
  color: ${({theme}) => theme.text.light_gray};
  font-weight: ${FontWeight.light};
`;
export const ErrorText = styled.div`
  color: red;
  text-align: center;
  height: 14px;
`;
export const Image = styled.img`
  padding-right: 10px;
`;

export const Description = styled.div`
  text-align: center;
`;

export const List = styled.ul`
  display: flex;

  flex-wrap: wrap;
`;

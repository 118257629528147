import React from 'react';
import ReactDOM from 'react-dom/client';
import {AppRouter} from 'src/routes/AppRouter';
import {BrowserRouter as Router} from 'react-router-dom';
import {Provider} from 'react-redux';
import {store} from './store';
import {ThemeLayout} from './layouts';

import './index.css';
import 'normalize.css';

import 'react-toastify/dist/ReactToastify.css';

import {ToastContainer} from 'react-toastify';

import {pdfjs} from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <Router>
    <Provider store={store}>
      <ThemeLayout>
        <AppRouter />
        <ToastContainer />
      </ThemeLayout>
    </Provider>
  </Router>,
);

import React from 'react';
import {useData} from './useData';

import {Wrapper, Footer, LoadingWrapper} from './ESignPage.styles';
import {Button, Container, FileUploader, Loader} from 'src/components';
import {useNavigate} from 'react-router-dom';
import {Routes} from 'src/routes';

export const ESignPage = () => {
  const {files, setFiles, onClose, success, isLoading} = useData();

  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Container>
        <LoadingWrapper>
          <Loader size="100px" />
        </LoadingWrapper>
      </Container>
    );
  }

  return (
    <Container>
      <Wrapper id="esign"></Wrapper>
      <Footer>
        <Button onClick={() => navigate(Routes.CLAIM_PREVIEW)}>
          Повернутись назад
        </Button>
        {success && <Button onClick={onClose}>Завершити реєстрацію</Button>}
      </Footer>
    </Container>
  );
};

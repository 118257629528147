export const baseEnv = () => ({
  DEFAULT_LOCALE: 'en',
  IS_PRODUCTION: true,
  IS_DEVELOPMENT: false,
  IS_TESTING: false,
  // BASE_URL: 'https://veteran.itsites.com.ua',
  BASE_URL: 'https://crmback.mva.gov.ua',

  SITE_URL: 'https://veteran.itsites.com.ua',
  API_VERSION: 1,
});

export type Environment = ReturnType<typeof baseEnv>;

import {all, call} from 'redux-saga/effects';

//watchers
import {watchClaim} from 'src/bus/claim/saga/watchers';
import {watchLocation} from 'src/bus/location/saga/watchers';
import {watchStatistic} from 'src/bus/statistic/saga/watchers';

function* rootSaga() {
  try {
    yield all([call(watchLocation), call(watchClaim), call(watchStatistic)]);
  } catch (error) {
    console.error('⛔️ error', error);
  }
}

export default rootSaga;

import React, {FC} from 'react';
import {CheckmarkIcon} from 'src/components/Icons';

import {Wrapper} from './Checkbox.styles';

type TProps = {
  value: boolean;
  onChange: (value: boolean) => any;
  margin?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  square?: boolean;
  error?: boolean;
};

export const Checkbox: FC<TProps> = ({
  onChange,
  value,
  margin,
  square,
  error = false,
}) => {
  return (
    <Wrapper
      error={error}
      margin={margin}
      type="button"
      onClick={(e) => {
        e.stopPropagation();
        onChange(!value);
      }}
      square={square}>
      {value && <CheckmarkIcon color="default" size="15px" />}
    </Wrapper>
  );
};

import {FontWeight} from 'src/themes';
import styled from 'styled-components';

type TProps = {
  hasPointer: boolean;
};

export const Wrapper = styled.li<TProps>`
  width: 32%;

  cursor: ${({hasPointer}) => (hasPointer ? 'pointer' : 'auto')};

  background-color: ${({theme}) => theme.background.dark};

  list-style: none;

  margin-bottom: 16px;
  border-radius: 8px;
  &:not(:nth-child(3n)) {
    margin-right: 2%;
  }
  /* padding-bottom: 100%; */
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

export const Content = styled.div`
  /* height: 100%; */
  position: absolute;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  padding: 33% 16px 10% 16px;
`;

export const Image = styled.img`
  max-width: 100%;
`;

export const Title = styled.div`
  color: ${({theme}) => theme.text.light};

  font-size: 16px;

  text-align: center;
`;

export const Count = styled.div`
  color: ${({theme}) => theme.text.light};

  font-size: 22px;
  font-weight: ${FontWeight.bold};
`;

export const Subtitle = styled.div`
  color: ${({theme}) => theme.text.light};

  font-size: 16px;
  margin-bottom: 28px;
`;

import styled from 'styled-components';

type TInputProps = {
  dark?: boolean;
};
export const ErrorText = styled.div`
  color: red;

  height: 14px;
`;

export const Wrapper = styled.div``;
export const DatePicker = styled.input<TInputProps>`
  width: 100%;

  height: 48px;

  font-size: 12px;

  transition: 0.3s all;
  background-color: ${({theme, dark}) =>
    dark ? theme.background.dark_gray : theme.background.default};
  padding: 0 16px;
  border: 1px solid ${({theme}) => theme.border.gray};
  outline: none;

  position: relative;
`;

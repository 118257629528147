import styled from 'styled-components';

export const Wrapper = styled.li`
  width: 32%;

  list-style: none;

  :not(:nth-child(3n)) {
    margin-right: 1%;
  }

  position: relative;

  margin-bottom: 16px;

  ${({theme}) => theme.mediaQueriesMax.md} {
    width: 49%;

    :not(:nth-child(3n)) {
      margin-right: 0;
    }
    :not(:nth-child(2n)) {
      margin-right: 2%;
    }
  }
`;

export const RemoveButton = styled.button`
  display: flex;

  justify-content: center;
  align-items: center;

  height: 32px;
  width: 32px;

  background-color: ${({theme}) => theme.background.dark_gray};
  border: none;

  border-radius: 100%;

  position: absolute;

  top: 8px;
  right: 8px;

  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
`;

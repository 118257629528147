import React, {FC, InputHTMLAttributes} from 'react';

import {DatePicker, Wrapper, ErrorText} from './DateField.styles';

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  dark?: boolean;

  errorEmpty?: boolean;
};

export const DateField: FC<TProps> = ({
  min = '1923-01-01',
  max = '2004-01-01',
  error,
  errorEmpty = false,
  ...props
}) => {
  return (
    <Wrapper>
      <DatePicker type="date" {...props} min={min} max={max} />
      {!errorEmpty && <ErrorText>{error || ''}</ErrorText>}
    </Wrapper>
  );
};

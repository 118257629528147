import {showToast} from 'src/services/toast';

type TArgs = {
  files: FileList | null;
  limit: number;
  curr: File[];
};

const TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/heic',
  'image/heif',
  'application/pdf',
];

const MAX_BYTES = 22020096;

export const transformFiles = ({files, limit, curr}: TArgs) => {
  if (files && files.length) {
    let arr: File[] = [];

    for (let i = 0; i < files.length; i++) {
      let size = files.item(i)?.size || 0;

      if (size < MAX_BYTES) {
        arr.push(files.item(i) as File);
      } else {
        showToast({
          message: 'Файл занадто великий',
          position: 'bottom-right',
          type: 'error',
          hideProgressBar: true,
        });
      }
    }

    const newArr = arr.filter(({type}) => TYPES.includes(type));

    const concatedArr = [...curr, ...newArr];

    if (concatedArr.length > limit) {
      showToast({
        message: 'Перевищений ліміт картинок',
        position: 'bottom-right',
        type: 'error',
        hideProgressBar: true,
      });
    }

    return concatedArr.slice(0, limit);
  }

  return curr;
};

import React, {FC, ReactNode} from 'react';

import {Wrapper} from './Container.styles';

type TProps = {
  children: ReactNode | ReactNode[];
};

export const Container: FC<TProps> = ({children}) => {
  return <Wrapper>{children}</Wrapper>;
};

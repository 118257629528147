import React, {
  FC,
  InputHTMLAttributes,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {CloseIcon} from 'src/components/Icons';
import {FilledField} from '../FilledField';

import {} from './Autocomplete';
import {
  Item,
  List,
  Wrapper,
  ErrorText,
  ClearButton,
} from './Autocomplete.styles';

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  width?: string;
  margin?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  error?: string;
  items: string[];
  onSelect: (item: any) => any;
  initValue: string;
  errorEmpty?: boolean;
};

export const Autocomplete: FC<TProps> = ({
  items,
  onSelect,
  initValue,
  error,
  errorEmpty = false,
  ...props
}) => {
  const [value, setValue] = useState(initValue || '');
  const [isFocused, setIsFocused] = useState(false);

  const filtered = useMemo(() => {
    if (value) {
      return items.filter(
        (item) => item.toLowerCase().indexOf(value?.toLowerCase()) !== -1,
      );
    }
    return items;
  }, [value, items]);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const isOpened = useMemo(() => {
    if (filtered.length) {
      if (isFocused) {
        return !filtered.includes(value);
      }
    }

    return false;
  }, [filtered, isFocused, value]);

  useEffect(() => {
    const index = filtered.findIndex(
      (item) => item.toLowerCase() === value.toLocaleLowerCase(),
    );

    if (index !== -1) {
      onSelect(filtered[index]);
    }
  }, [value, filtered]);

  return (
    <Wrapper>
      <FilledField
        value={value}
        type="text"
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setTimeout(() => {
            setIsFocused(false);
          }, 200);
        }}
        onChange={({target}) => {
          setValue(target.value);
          onSelect('');
        }}
        rightIcon={
          <ClearButton
            type="button"
            onClick={() => {
              setValue('');
              onSelect('');
            }}>
            <CloseIcon size="28px" />
          </ClearButton>
        }
        {...props}
      />
      {isOpened && (
        <List>
          {filtered.map((item, index) => (
            <Item
              role="button"
              onClick={() => {
                onSelect(item);
              }}
              key={`address-${index}-${item}`}>
              {item}
            </Item>
          ))}
        </List>
      )}
      {!errorEmpty && <ErrorText>{error || ''}</ErrorText>}
    </Wrapper>
  );
};

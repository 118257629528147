import {format} from 'date-fns';
import {uk} from 'date-fns/locale';

import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Container, Loader} from 'src/components';
import {Routes} from 'src/routes';

import {
  Header,
  HeaderWrapper,
  Wrapper,
  Label,
  List,
  Item,
  Footer,
  LoaderWrapper,
  ButtonsWrapper,
} from './ClaimPreview.styles';
import {useData} from './useData';

export const ClaimPreview = () => {
  const {detail, onConfirm, isLoading} = useData();

  const navigate = useNavigate();

  return (
    <>
      {!isLoading ? (
        <Wrapper>
          <Container>
            <Header>
              <div></div>
              <HeaderWrapper>
                <Label>
                  Виконавчому директору бюджетної установи "Український
                  ветеранський фонд"
                </Label>

                <Label>від {detail?.full_name}</Label>
                <Label>
                  який (яка) проживає за адресою:
                  <br />
                  {detail?.actual_address?.region_name}
                  {', '}
                  {detail?.actual_address?.full_name},{' '}
                  {detail?.actual_address?.street}
                  {', '}
                  {detail?.actual_address?.house} Індекс:
                  {detail?.actual_address?.zip_code} <br />
                  +38{detail?.tel}
                  <br />
                  {detail?.email}
                  <br />
                  <br />
                </Label>
              </HeaderWrapper>
            </Header>
            <Label>
              Прошу надати одноразову фінансову допомогу на відшкодування
              витрат, пов’язаних з закупівлею предметів, товарів для ведення
              підприємницької, або незалежної професійної діяльності, а саме:
              <br />
              {detail?.description}
              <br />У сумі {detail?.price} грн
            </Label>
            <Label>
              Грошову допомогу прошу перерахувати за такими реквізитами: <br />
              {detail?.bank_name} <br />
              {detail?.iban}
            </Label>
            <Label>До заяви додаю копії таких документів:</Label>
            <Label>
              <List>
                <Item>Документа, що посвідчує особу громадянина України;</Item>
                <Item>
                  Документа про набуття статусу підприємця фізичною особою (для
                  фізичних осіб-підприємців) або Документа про взяття на облік
                  самозайнятої особи;
                </Item>
                <Item>
                  Документа, що підтверджує один із статусів ветерана війни,
                  особи, яка має особливі заслуги перед Батьківщиною,
                  постраждалого учасника Революції Гідності, члена сім’ї
                  загиблого (померлого) Захисника чи Захисниці України або
                  належність до членів сім’ї ветерана;
                </Item>
                <Item>
                  Документа, що підтверджує взяття особи на облік внутрішньо
                  переміщеної особи;
                </Item>
                <Item>
                  Документа про понесення фактичних витрат, пов’язаних із
                  здійсненням підприємницької або незалежної професійної
                  діяльності самозайнятою особою, які можуть бути відшкодовані
                  за рахунок Фонду.
                </Item>
              </List>
            </Label>
            <Footer>
              {format(new Date(), 'dd.LL.yyyy kk:mm ', {
                locale: uk,
              })}
            </Footer>
            <ButtonsWrapper>
              <Button onClick={() => navigate(Routes.CLAIM_CREATE)}>
                Назад
              </Button>
              <Button onClick={onConfirm}>Підписати</Button>
            </ButtonsWrapper>
          </Container>
        </Wrapper>
      ) : (
        <LoaderWrapper>
          <Loader size="100px" />
        </LoaderWrapper>
      )}
    </>
  );
};

import {Location} from './';

export enum types {
  FETCH_ITEMS = 'LOCATION/FETCH_ITEMS',
}

export type LocationState = {
  regions: string[];
  district_name: Location.Address;
  community: Location.Address;
  full_name: Location.Address;
};

export type FetchItemsAsync = {
  type: typeof types.FETCH_ITEMS;
  payload: Location.ReqFetchItems;
};

export type LocationActionTypes = FetchItemsAsync;

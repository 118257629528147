import {FontWeight} from 'src/themes';
import styled from 'styled-components';

type TWrapperProps = {
  margin: {
    top: string;
    bottom: string;
    left: string;
    right: string;
  };
  width: string;
  height: string;
};

export const Wrapper = styled.div<TWrapperProps>`
  width: ${({width}) => width};
  height: ${({height}) => height};

  display: flex;
  flex-direction: column;
  ${({margin}) => `
    margin-top: ${margin.top};
    margin-bottom: ${margin.bottom};
    margin-right: ${margin.right};
    margin-left: ${margin.left};
  `};
`;

export const Input = styled.textarea`
  font-size: 15px;

  color: ${({theme}) => theme.text.default};
  font-weight: ${FontWeight.light};

  transition: 0.3s all;
  background-color: ${({theme}) => theme.background.default};

  padding: 16px;
  border: 1px solid ${({theme}) => theme.border.gray};

  outline: none;

  resize: none;

  height: 100%;
`;
export const ErrorText = styled.div`
  color: red;
`;

import React, {ChangeEvent, DragEvent, FC, useCallback, useRef} from 'react';
import {transformFiles} from 'src/helpers';
import fileImage from 'src/assets/images/File.png';
import {
  Wrapper,
  ErrorText,
  Image,
  Description,
  List,
} from './FileUploader.styles';
import {ImageCard} from 'src/components/Cards';

type TProps = {
  onChangeFiles: (file: File[]) => any;
  files: File[];
  limit?: number;
  error?: string;
  errorEmpty?: boolean;
};

export const FileUploader: FC<TProps> = ({
  files,
  onChangeFiles,
  limit = 10,
  error,
  errorEmpty = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const onOpenDialog = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, [inputRef]);

  const onDrop = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const {files: fileList} = e.dataTransfer;

    onChangeFiles(transformFiles({files: fileList, limit, curr: files}));
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeFiles(transformFiles({files: e.target.files, limit, curr: files}));
    e.target.value = '';
  };
  return (
    <>
      <Wrapper
        role="button"
        onClick={onOpenDialog}
        onDragEnter={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDragOver={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        onDrop={onDrop}>
        {!!files.length && `Ви додали ${files.length}`}

        <input
          hidden
          ref={inputRef}
          type="file"
          multiple
          onChange={onChange}
          accept=".pdf, .jpeg, .png, .jpg, .heic, .heif"
        />
        {!files.length && (
          <>
            <Image src={fileImage} alt="File image"></Image>Перетягніть файл
            сюди або натисніть, щоб завантажити
          </>
        )}
      </Wrapper>
      {!files.length && (
        <Description>
          Доступні формати файлів: PDF, JPEG, PNG. Розмір одного файлу не
          повинен перевищувати 20 МБ.
        </Description>
      )}

      <List>
        {files.map((file, index) => (
          <>
            <ImageCard
              file={file}
              onRemove={() => {
                onChangeFiles(files.filter((item, i) => index !== i));
              }}
              key={`image-${file.name}-${index}`}
            />
          </>
        ))}
      </List>
      {!errorEmpty && <ErrorText>{error || ''}</ErrorText>}
    </>
  );
};

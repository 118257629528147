import React, {FC, ReactNode} from 'react';
import {useDispatch} from 'react-redux';
import {ThemeProvider} from 'styled-components';
//app themes
import {media, ThemeContext, themes, ThemesName} from 'src/themes';
import {light} from 'src/themes/palletes';
//store
// import {themesSelectors, themesActions} from '/bus/themes';
//@ts-ignore
import ReactBreakpoints from 'react-breakpoints';

type TProps = {
  children: ReactNode;
};

export const ThemeLayout: FC<TProps> = ({children}) => {
  const dispatch = useDispatch();
  //   const mottoType = useSelector(themesSelectors.getType);

  //   useEffect(() => {
  //     dispatch(themesActions.fetchTypeAsync());
  //   }, [dispatch]);

  return (
    <ThemeProvider theme={{...light, ...media}}>
      <ThemeContext.Provider value={themes[ThemesName.LIGHT]}>
        <ReactBreakpoints breakpoints={media.breakpointMap}>
          {children}
        </ReactBreakpoints>
      </ThemeContext.Provider>
    </ThemeProvider>
  );
};

import MaskedInput from 'react-text-mask';
import {FontWeight} from 'src/themes';
import styled from 'styled-components';

type TWrapperProps = {
  width: string;
};

export const Wrapper = styled.div<TWrapperProps>`
  width: ${({width}) => width};
`;
export const Code = styled.span`
  font-weight: ${FontWeight.medium};
  font-size: 12px;
  padding-left: 16px;
`;

export const InputWrapper = styled.div`
  background-color: ${({theme}) => theme.background.default};
  display: flex;
  align-items: center;
  border: 1px solid ${({theme}) => theme.border.gray};
  height: 48px;
`;
export const Input = styled(MaskedInput)`
  height: 48px;

  font-size: 15px;

  color: ${({theme}) => theme.text.default};

  transition: 0.3s all;
  background-color: transparent;

  padding: 0 16px 0 4px;
  border: none;

  outline: none;

  width: 100%;

  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 -2px ${({theme}) => theme.background.default} inset !important;
  }
  :-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0 30px ${({theme}) => theme.background.default}
      inset !important;
  }
  :-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px ${({theme}) => theme.background.default}
      inset !important;
  }
  :-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${({theme}) => theme.background.default}
      inset !important;
  }
`;
export const ErrorText = styled.div`
  color: red;
`;

import React, {FC} from 'react';
import {useTheme} from 'src/hooks';
import {TColorsIcon} from 'src/themes';

type TProps = {
  size: string;
  color: keyof TColorsIcon;
};

export const CheckmarkIcon: FC<TProps> = ({size, color}) => {
  const {pallete} = useTheme();

  return (
    <svg width={size} height={size} viewBox="0 0 12 9" fill="none">
      <path
        d="M1 4.5L4.5 8L11.5 1"
        stroke={pallete.icon[color]}
        strokeWidth="2"
      />
    </svg>
  );
};

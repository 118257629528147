import styled from 'styled-components';

export const Wrapper = styled.div`
  & iframe {
    height: 60vh;

    ${({theme}) => theme.mediaQueriesMax.md} {
      height: 80vh;
    }
  }
`;

export const Footer = styled.div`
  display: flex;

  justify-content: space-between;
`;

export const LoadingWrapper = styled.div`
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
`;

import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;
export const List = styled.ul`
  position: absolute;

  min-height: 40px;
  max-height: 200px;

  overflow-y: auto;

  width: 100%;
  padding-left: 0;

  margin: 0;

  background-color: ${({theme}) => theme.background.default};

  padding: 16px;

  margin-top: -5px;

  z-index: 10;
`;
export const Item = styled.li`
  list-style: none;
`;
export const ErrorText = styled.div`
  color: red;

  height: 14px;
`;

export const ClearButton = styled.button`
  height: 100%;
  width: 36px;

  display: flex;

  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;
`;

import {yupResolver} from '@hookform/resolvers/yup';
import {useCallback, useEffect, useState, useMemo} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Claim, claimActions, claimSelectors} from 'src/bus/claim';
import {Location} from 'src/bus/location';
import {Routes} from 'src/routes';
import {showToast} from 'src/services/toast';
import {schema} from './validate';

const transformPassport = (data: string): string => {
  const leters = data.slice(0, 2).toUpperCase();

  return `${leters}${data.replace(/\D/g, '')}`;
};

export const useData = () => {
  const dispatch = useDispatch();

  const preview = useSelector(claimSelectors.getDetailPreview);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    formState: {errors},
    setValue,
    watch,
    clearErrors,
    resetField,
  } = useForm<Claim.CreateItemForm>({
    defaultValues: {
      bank_name: preview?.bank_name || '',
      category:
        preview && preview?.category !== null ? preview?.category : null,
      description: preview?.description || '',
      economic_activity:
        preview && preview?.economic_activity !== null
          ? preview?.economic_activity
          : null,
      email: preview?.email || '',
      iban: preview?.iban || '',
      ipn: preview?.ipn || '',
      passport: preview?.passport || '',
      name: preview?.full_name || '',

      price: preview?.price || 0,

      actual_address: {
        community: preview?.actual_address.community || '',
        district_name: preview?.actual_address.district_name || '',
        full_name: preview?.actual_address.full_name || '',
        region_name: preview?.actual_address.region_name || '',
        id: preview?.actual_address.address_id || 0,
      },
      registration_address: {
        community: preview?.registration_address.community || '',
        district_name: preview?.registration_address.district_name || '',
        full_name: preview?.registration_address.full_name || '',
        region_name: preview?.registration_address.region_name || '',
        id: preview?.registration_address.address_id || 0,
      },

      isPassport: !!preview?.passport,

      sex: preview?.sex || true,
      tel: preview?.tel || '',
      birthday: preview?.birthday || '1975-01-01',
      confirm_email: '',

      correspondence_address: preview?.correspondence_address || '',
      economic_description: preview?.economic_description || '',
      family_ties: preview?.family_ties || '',
      full_name_participant_hostilities:
        preview?.full_name_participant_hostilities || '',
      certificate_participant_hostilities:
        preview?.certificate_participant_hostilities || '',
      actual_costs_documents: preview?.actual_costs_document || [],
      army_documents: preview?.army_costs_document || [],
      citizen_documents: preview?.citizen_document || [],
      employed_documents: preview?.employed_document || [],
      internally_documents: preview?.internally_document || [],
      bank_account_document: preview?.bank_account_document || [],

      reg_address: {
        apartment: preview?.registration_address.apartment || '',
        house: preview?.registration_address.house || '',
        street: preview?.registration_address.street || '',
        zip_code: preview?.registration_address.zip_code || '',
      },
      act_address: {
        apartment: preview?.actual_address.apartment || '',
        house: preview?.actual_address.house || '',
        street: preview?.actual_address.street || '',
        zip_code: preview?.actual_address.zip_code || '',
      },
      c1: false,
      c2: false,
      c3: false,
      c4: false,
    },
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const isPassport = watch('isPassport');

  const sex = watch('sex');
  const category = watch('category');
  const economicActivity = watch('economic_activity');

  const actual_costs_documents = watch('actual_costs_documents');
  const army_documents = watch('army_documents');
  const citizen_documents = watch('citizen_documents');
  const employed_documents = watch('employed_documents');
  const internally_documents = watch('internally_documents');
  const bank_account_document = watch('bank_account_document');

  const c1 = watch('c1');
  const c2 = watch('c2');
  const c3 = watch('c3');
  const c4 = watch('c4');

  const onChangeIsPassport = useCallback((value: boolean) => {
    if (value) {
      setValue('ipn', '');
      clearErrors('ipn');
    } else {
      clearErrors('passport');
      setValue('passport', '');
    }

    setValue('isPassport', value);
  }, []);

  const registration_address = watch('registration_address');
  const actual_address = watch('actual_address');

  const onSubmit = (data: Claim.CreateItemForm) => {
    const newData: Claim.ReqCreateItem = {
      actual_costs_document: data.actual_costs_documents,
      army_costs_document: data.army_documents,
      bank_name: data.bank_name,
      birthday: data.birthday,
      category: !!data.category,
      citizen_document: data.citizen_documents,
      correspondence_address: data.correspondence_address,
      description: data.description,
      economic_activity: !!data.economic_activity,
      email: data.email,
      employed_document: data.employed_documents,
      full_name: data.name,
      iban: data.iban,
      internally_document: data.internally_documents,
      bank_account_document: data.bank_account_document,
      ipn: !data.isPassport ? data.ipn.replace(/\D/g, '') : '',
      passport: data.isPassport ? data.passport : '',
      price: +(data.price.toString().replace(/\D/g, '') || 0),
      sex: data.sex,
      tel: data.tel,
      economic_description: data.economic_description,
      family_ties: data.family_ties,
      full_name_participant_hostilities: data.full_name_participant_hostilities,
      certificate_participant_hostilities: data.certificate_participant_hostilities,
      registration_address: {
        address_id: registration_address.id,
        region_name: registration_address.region_name,
        full_name: registration_address.full_name,
        community: registration_address.community,
        district_name: registration_address.district_name,
        ...data.reg_address,
      },
      actual_address: {
        address_id: actual_address.id,
        region_name: actual_address.region_name,
        full_name: actual_address.full_name,
        community: actual_address.community,
        district_name: actual_address.district_name,
        ...data.act_address,
      },
    };

    dispatch(claimActions.savePreviewDetail(newData));
    navigate(Routes.CLAIM_PREVIEW);
  };

  const onChangeSex = useCallback((value: boolean) => {
    setValue('sex', value);
  }, []);

  const onChangeCategory = useCallback((value: boolean) => {
    setValue('category', value);
    clearErrors('category');
  }, []);

  const onChangeEconomicActivity = useCallback((value: boolean) => {
    setValue('economic_activity', value);
    clearErrors('economic_activity');
  }, []);

  const onChangeImage = useCallback((images: File[], name: Claim.Images) => {
    setValue(name, images);
    clearErrors(name);
  }, []);

  const onChange = useCallback(
    (name: 'c4' | 'c3' | 'c2' | 'c1', value: boolean) => {
      setValue(name, value);
      clearErrors(name);
    },
    [],
  );

  const onChangeActualAddress = (item: Location.ItemCustom) => {
    setValue('actual_address', item as any);
    if (item.full_name) {
      clearErrors('actual_address.id');
    }
  };

  const onChangeRegistationAddress = (item: Location.ItemCustom) => {
    setValue('registration_address', item as any);
    if (item.full_name) {
      clearErrors('registration_address.id');
    }
  };

  useEffect(() => {
    console.log(registration_address);

    if (!registration_address?.id) {
      resetField('reg_address');
    }
  }, [registration_address]);

  useEffect(() => {
    if (!actual_address?.id) {
      resetField('act_address');
    }
  }, [actual_address]);

  useEffect(() => {
    const arr = Object.values(errors);

    if (arr.length) {
      showToast({
        message: 'Перевірте коректність заповнення усіх полів',
        position: 'bottom-right',
        type: 'error',
        hideProgressBar: true,
      });
    }
  }, [errors]);
  return {
    control,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    sex,
    onChangeSex,
    onChangeCategory,
    category,
    onChangeEconomicActivity,
    economicActivity,
    onChangeImage,
    actual_costs_documents,
    army_documents,
    internally_documents,
    citizen_documents,
    employed_documents,
    registration_address,
    actual_address,
    bank_account_document,
    c1,
    c2,
    c3,
    c4,
    onChange,
    onChangeActualAddress,
    onChangeRegistationAddress,
    isPassport,
    onChangeIsPassport,
  };
};

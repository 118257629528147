import {FC, SelectHTMLAttributes} from 'react';
import {Select} from './SelectBox.styles';

type TProps = SelectHTMLAttributes<HTMLSelectElement> & {
  children: any;
  width?: string;
  height?: string;
};
export const SelectBox: FC<TProps> = ({
  children,
  width = '300px',
  height = '50px',
  ...props
}) => {
  return (
    <Select {...props} width={width} height={height}>
      {children}
    </Select>
  );
};

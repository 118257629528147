import React, {FC, ReactNode, useEffect} from 'react';

import {Routes} from 'src/routes';

import {useLocation, useNavigate} from 'react-router-dom';

type TProps = {
  children: any;
  to: keyof typeof Routes;
};

export const Redirect: FC<TProps> = ({children, to}) => {
  const {pathname} = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (to !== pathname) {
      navigate(Routes[to], {replace: true});
    }
  }, [to, pathname]);

  return children;
};

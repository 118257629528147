import React, {FC, InputHTMLAttributes, useMemo} from 'react';
import {FieldError} from 'react-hook-form';

import {Wrapper, Input, ErrorText} from './AreaField.styles';

type TProps = InputHTMLAttributes<HTMLTextAreaElement> & {
  width?: string;
  margin?: {
    top?: string;
    bottom?: string;
    left?: string;
    right?: string;
  };
  error?: string;
  height?: string;
};

export const AreaField: FC<TProps> = ({
  width = '250px',
  height = '88px',
  margin,
  error,
  ...props
}) => {
  const margins = useMemo(
    () => ({
      bottom: margin?.bottom || '0',
      top: margin?.top || '0',
      right: margin?.right || '0',
      left: margin?.left || '0',
    }),
    [margin],
  );

  return (
    <Wrapper width={width} margin={margins} height={height}>
      <Input {...props} />
      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};

import React, {FC, InputHTMLAttributes, useMemo, useRef, useState} from 'react';
import MaskedInput from 'react-text-mask';

import {
  Code,
  ErrorText,
  Input,
  InputWrapper,
  Wrapper,
} from './MaskedField.styles';

type TMask = 'phone' | 'iban' | 'passport' | 'index';

type TProps = InputHTMLAttributes<HTMLInputElement> & {
  mask?: TMask;
  width: string;
  error?: string;
  maskChar?: string;
};

export const MaskedField: FC<TProps> = ({
  mask,
  width = '100%',
  maskChar = '_',
  error,
  ...props
}) => {
  const ref = useRef<MaskedInput>(null);

  const maskData = useMemo(() => {
    switch (mask) {
      case 'phone':
        return [
          '(',
          /\d/,
          /\d/,
          /\d/,
          ')',
          /\d/,
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
          ' ',
          /\d/,
          /\d/,
        ];
      case 'iban':
        return [
          'U',
          'A',
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ];

      case 'index':
        return [/\d/, /\d/, /\d/, /\d/, /\d/];
      default:
        return [];
    }
  }, [mask]);

  return (
    <Wrapper
      width={width}
      onClick={() => {
        if (ref.current) {
          ref.current.inputElement.focus();
        }
      }}>
      <InputWrapper>
        {mask === 'phone' && <Code>+38 </Code>}
        <Input
          ref={ref}
          mask={maskData}
          {...props}
          autoComplete="off"
          placeholderChar={maskChar}
        />
      </InputWrapper>
      {error && <ErrorText>{error}</ErrorText>}
    </Wrapper>
  );
};

import type {Pallete} from './types';

const pallete: Pallete = {
  text: {
    default: '#2E2E2E',
    dark: '#092043',
    gray: '#222222',
    light_gray: '#8e8e8e',
    black: '#000000',
    light: '#fff',
    danger: '#eb5656',
    success: '#0a9225',
    blue: '#3171d4',
  },
  background: {
    default: '#f6f6f6',
    light: '#ffffff',
    gray: '#fff',
    dark: '#092043',
    dark_gray: '#dcdcdc',
  },
  icon: {
    default: '#092043',
  },
  border: {
    default: '#092043',
    gray: 'gray',
    error: 'red',
    transparent: 'transparent',
  },
  line: {
    default: '#c2c2c2',
  },
  button: {
    background: {
      default: '#092043',
      light: '#fff',
      green: '#0a9225',
      danger: '#eb5656',
    },
    border: {
      default: 'transparent',
      light: 'gray',
      green: 'transparent',
      danger: 'transparent',
    },
    text: {
      default: '#FFFFFF',
      light: '#3f3f3f',
      green: '#FFFFFF',
      danger: '#fff',
    },
  },
};

export default pallete;

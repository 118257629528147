import styled from 'styled-components';
import {FontWeight} from 'src/themes';

type TProps = {
  blue?: boolean;
};
type TCheckboxProps = {
  padding?: string;
};
export const Wrapper = styled.section`
  width: 100%;

  background-color: ${({theme}) => theme.background.default};

  padding: 64px 0;
`;

export const Content = styled.form`
  background-color: ${({theme}) => theme.background.gray};

  min-height: 100vh;

  width: 75%;
  margin: 0 auto;
  ${({theme}) => theme.mediaQueriesMax.sm} {
    width: 100%;
  }
  padding: 32px 0;
`;

export const Title = styled.h1`
  margin-top: 0;

  height: 80px;
  font-weight: ${FontWeight.light};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 23px;
  color: ${({theme}) => theme.text.gray};
  border-bottom: 1px solid ${({theme}) => theme.border.gray};
`;

export const FieldWrapper = styled.div``;

export const HalfWrapper = styled.div`
  display: grid;

  grid-template-columns: 1fr 1fr;

  column-gap: 16px;
  ${({theme}) => theme.mediaQueriesMax.sm} {
    grid-template-columns: 1fr;

    row-gap: 8px;
  }
`;

export const Section = styled.div`
  padding: 32px;

  border-bottom: 1px solid ${({theme}) => theme.border.gray};
`;

export const CheckboxWrapper = styled.div<TCheckboxProps>`
  display: flex;
  flex: 1;
  padding-top: ${(props) => props.padding};
  align-self: center;

  cursor: pointer;
`;

export const CheckboxLabel = styled.p`
  /* display: flex; */
  flex: 1;

  /* align-self: center; */

  align-self: center;

  padding: 0;
  margin: 0;

  margin-left: 8px;
  cursor: pointer;

  & > a {
    color: ${({theme}) => theme.text.blue};

    text-decoration: none;
  }
`;

export const Label = styled.p<TProps>`
  color: ${({theme, blue = true}) =>
    blue ? theme.text.dark : theme.text.black};
  margin: 24px 0 16px 0;
  font-size: 15px;
  display: block;
  font-weight: ${FontWeight.light};
  & > span {
    color: red;
  }
`;

export const CategoryWrapper = styled.div`
  display: grid;

  grid-template-columns: 36px 1fr;
  column-gap: 8px;

  width: 100%;
  margin-bottom: 40px;
`;
export const CategoryLabel = styled.div`
  font-size: 14px;
`;
export const CategoryMainLabel = styled.p`
  height: 28px;
  color: ${({theme}) => theme.text.dark};
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: ${FontWeight.light};
  padding: 10px 0;
`;

export const Footer = styled.div`
  padding: 0 32px;

  margin-top: 32px;
`;
export const BottomCheckboxes = styled.div`
  & > div {
    padding-bottom: 20px;
  }
  padding: 0 32px;
`;
export const Uploaders = styled.div`
  padding: 32px;
`;

export const Address = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  column-gap: 16px;
`;
export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

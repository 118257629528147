import {RootState} from 'src/store/rootReducer';

export const getItems = (state: RootState) => state.claim.items;
export const getPage = (state: RootState) => state.claim.current_page;
export const getTotalPage = (state: RootState) => state.claim.total_page;
export const getSorter = (state: RootState) => state.claim.sorter;
export const getDetail = (state: RootState) => state.claim.detail;
export const getDetailPreview = (state: RootState) =>
  state.claim.detail_preview;
export const getFilter = (state: RootState) => state.claim.filter;
export const getCount = (state: RootState) => state.claim.count;

export const getSuccess = (state: RootState) => state.claim.success;

import {uiSelectors} from 'src/bus/ui';
import {claimActions} from './../../bus/claim/slice';
import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {claimSelectors} from 'src/bus/claim';
import {useNavigate} from 'react-router-dom';
import {Routes} from 'src/routes';
export const useData = () => {
  const dispatch = useDispatch();

  const detail = useSelector(claimSelectors.getDetailPreview);
  const isLoading = useSelector(uiSelectors.getLoading('claim'));

  const navigate = useNavigate();

  const onConfirm = useCallback(() => {
    if (detail) {
      navigate(Routes.ESIGN);
    }
  }, [detail]);

  return {detail, onConfirm, isLoading};
};

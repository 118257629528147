import {combineReducers} from '@reduxjs/toolkit';

//types
import {AppState} from 'src/bus/app/types';
import {LocationState} from 'src/bus/location/types';
// import {ThemesState} from '/bus/themes/types';
import {UiState} from 'src/bus/ui/types';
import {ClaimState} from 'src/bus/claim/types';
import {StatisticState} from 'src/bus/statistic/types';

//reducers
import {appReducer} from 'src/bus/app';
// import {themesReducer} from '/bus/themes';
import {uiReducer} from 'src/bus/ui';
import {locationReducer} from 'src/bus/location';
import {claimReducer} from 'src/bus/claim';
import {statisticReducer} from 'src/bus/statistic';

const rootReducer = combineReducers({
  app: appReducer,
  // themes: themesReducer,
  ui: uiReducer,
  location: locationReducer,
  claim: claimReducer,
  statistic: statisticReducer,
});

export type RootState = {
  app: AppState;
  // themes: ThemesState;
  ui: UiState;
  location: LocationState;
  claim: ClaimState;
  statistic: StatisticState;
};

export default rootReducer;

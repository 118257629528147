import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {App} from './namespace';

import {AppState, types} from './types';

const initialState: AppState = {
  initialized: true,
  price: 0,
  menu: false,
  per: 5,
};

const slice = createSlice({
  name: 'App',
  initialState,
  reducers: {
    changePer: (state: AppState, action: PayloadAction<number>) => {
      state.per = action.payload;
    },
    toggleInitialized: (state: AppState, action: PayloadAction<boolean>) => {
      state.initialized = action.payload;
    },
    updatePrice: (state: AppState, action: PayloadAction<number>) => {
      state.price = action.payload;
    },
    toggleMenu: (state: AppState, action: PayloadAction<boolean>) => {
      state.menu = action.payload;
    },
  },
});

export default slice.reducer;

export const appActions = {
  ...slice.actions,
};

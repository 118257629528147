import React, {FC} from 'react';
import {Statistic} from 'src/bus/statistic';
import {
  Wrapper,
  Image,
  Count,
  Content,
  Title,
  Subtitle,
} from './StatisticCard.styles';

import ENV from 'src/configs';

type TProps = {
  statistic: Statistic.Item;
  onClick: () => any;
  hasPointer?: boolean;
};

export const StatisticCard: FC<TProps> = ({
  onClick,
  statistic,
  hasPointer = false,
}) => {
  return (
    <Wrapper hasPointer={hasPointer} onClick={onClick}>
      <Content>
        <Image src={`${ENV.BASE_URL}${statistic.picture}`} />
        <Count>{statistic.count}</Count>
        <Subtitle>{statistic.subtitle}</Subtitle>
        <Title>{statistic.title}</Title>
      </Content>
    </Wrapper>
  );
};

import React, {FC, useCallback, useState} from 'react';
import {CloseIcon} from 'src/components/Icons';
import {Wrapper, Image, RemoveButton} from './ImageCard.styles';

import {Document, Page} from 'react-pdf';

type TProps = {
  file: File;

  onRemove: () => any;
};

export const ImageCard: FC<TProps> = ({file, onRemove}) => {
  const [page, setPage] = useState(0);

  return (
    <Wrapper>
      {file.type === 'application/pdf' ? (
        <Document
          file={file}
          onLoadSuccess={({numPages}) => setPage(numPages)}
          className="pdf">
          {!!page && <Page pageNumber={1} className="pdf" />}
        </Document>
      ) : (
        <Image src={URL.createObjectURL(file)} />
      )}

      <RemoveButton onClick={onRemove}>
        <CloseIcon size="28px" />
      </RemoveButton>
    </Wrapper>
  );
};

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {extractAddressType} from 'src/helpers';
import {Location} from './namespace';

import {types, LocationActionTypes, LocationState} from './types';

const regions = [
  'Автономна Республіка Крим',
  'Вінницька область',
  'Волинська область',
  'Дніпропетровська область',
  'Донецька область',
  'Житомирська область',
  'Закарпатська область',
  'Запорізька область',
  'Івано-Франківська область',
  'Київ',
  'Київська область',
  'Кіровоградська область',
  'Луганська область',
  'Львівська область',
  'Миколаївська область',
  'Одеська область',
  'Полтавська область',
  'Рівненська область',
  'Сумська область',
  'Севастополь',
  'Тернопільська область',
  'Харківська область',
  'Херсонська область',
  'Хмельницька область',
  'Черкаська область',
  'Чернівецька область',
  'Чернігівська область',
];

const initialState: LocationState = {
  regions,
  community: {actual: [], registration: []},
  full_name: {actual: [], registration: []},
  district_name: {actual: [], registration: []},
};

const slice = createSlice({
  initialState,
  name: 'Location',
  reducers: {
    saveAddress: (
      state: LocationState,
      action: PayloadAction<Location.SaveAddress>,
    ) => {
      //@ts-ignore

      //@ts-ignore
      state[extractAddressType({type: action.payload.type})][
        action.payload.name
      ] = action.payload.items;
    },
  },
});

export default slice.reducer;

export const locationActions = {
  ...slice.actions,
  fetchItemsAsync: (payload: Location.ReqFetchItems): LocationActionTypes => ({
    type: types.FETCH_ITEMS,
    payload,
  }),
};

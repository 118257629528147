import {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Location, locationActions, locationSelectors} from 'src/bus/location';
import {uiActions} from 'src/bus/ui';

type TArgs = {
  type: Location.AddressTypes;
  value: string;
};

const formatAddress = (item: TArgs, obj: Location.ItemCustom): any => {
  switch (item.type) {
    case 'region_name':
      return {
        region_name: obj.region_name,
      };
    case 'district_name':
      return {
        region_name: obj.region_name,
        district_name: obj.district_name,
      };
    case 'community':
      return {
        region_name: obj.region_name,
        district_name: obj.district_name,
        community: obj.community,
      };

    case 'full_name':
      return {
        region_name: obj.region_name,
        district_name: obj.district_name,
        community: obj.community,
        full_name: obj.full_name,
      };
  }
};

export const useData = (
  oldAddress: Location.ItemCustom,
  name: Location.AddressNames,
) => {
  const dispatch = useDispatch();

  const [type, setType] = useState<Location.AddressTypes | ''>('');

  const regions = useSelector(locationSelectors.getRegions);

  const districts = useSelector(locationSelectors.getDistrict)[name];
  const community = useSelector(locationSelectors.getComunity)[name];
  const fullName = useSelector(locationSelectors.getFullName)[name];
  const [address, setAddress] = useState<Location.ItemCustom>({
    community: oldAddress.community || '',
    district_name: oldAddress.district_name || '',
    full_name: oldAddress.full_name || '',
    region_name: oldAddress.region_name || '',
  });

  const onSelectItem = useCallback(
    (item: TArgs) => {
      const obj = {
        ...address,
        [item.type]: item.value,
      };

      const newValue = formatAddress(item, obj);
      console.log(11);

      setAddress(newValue);
      setType(item.type);
    },
    [type, address],
  );

  const onBootstrap = useCallback(() => {
    if (type && type !== 'full_name' && address.region_name) {
      dispatch(
        locationActions.fetchItemsAsync({
          key: name,
          params: address as any,
          type: type as any,
        }),
      );
    }
  }, [type, address]);

  useEffect(() => {
    onBootstrap();
  }, [onBootstrap]);

  const districtsData = useMemo(() => {
    const arr = districts.map(({district_name}) => district_name);

    return Array.from(new Set(arr));
  }, [districts]);

  const communityData = useMemo(() => {
    const arr = community.map(({community}) => community);

    return Array.from(new Set(arr));
  }, [community]);

  const fullNameData = useMemo(() => {
    const arr = fullName.map(({full_name}) => full_name);

    return Array.from(new Set(arr));
  }, [fullName]);

  const id = useMemo(() => {
    if (type === 'full_name') {
      if (address.id) {
        return address.id;
      }

      const item = fullName.find(
        ({full_name}) => address.full_name === full_name,
      );

      if (item) {
        return item.id;
      }
    }

    return 0;
  }, [address]);

  useEffect(() => {
    if (oldAddress.id) {
      setAddress({
        community: oldAddress.community || '',
        district_name: oldAddress.district_name || '',
        full_name: oldAddress.full_name || '',
        region_name: oldAddress.region_name || '',
        id: oldAddress.id || 0,
      });
      setType('full_name');
    }
  }, [oldAddress]);

  useEffect(() => {
    if (
      (address.region_name === 'Київ' && address.full_name !== 'місто Київ') ||
      (address.region_name === 'Севастополь' &&
        address.full_name !== 'місто Севастополь')
    ) {
      if (districts.length)
        setAddress({
          ...address,
          community: districts[0].community,
          district_name: districts[0].district_name,
          full_name: districts[0].full_name,
          id: districts[0].id,
        });

      setType('full_name');
    }
  }, [address, districts, type]);

  return {
    address,
    type,
    regions,
    onSelectItem,
    community: communityData,
    districts: districtsData,
    fullName: fullNameData,
    id,
  };
};

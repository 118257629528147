import styled from 'styled-components';

type TProps = {
  height: string;
  width: string;
};
export const Select = styled.select<TProps>`
  width: ${({width}) => width};
  height: ${({height}) => height};
  background-color: ${({theme}) => theme.background.default};
  padding: 0 16px;

  border: 1px solid ${({theme}) => theme.border.gray};
`;

import {array, boolean, mixed, number, object, ref, string} from 'yup';
export const schema = object({
  email: string()
    .required('Введіть, будь ласка, коректний email')
    .email('Введіть, будь ласка, коректний email'),
  confirm_email: string()
    .required('Введіть, будь ласка, коректний email')
    .oneOf([ref('email'), null], 'Email не співпадає'),
  bank_name: string()
    .required('Введіть, будь ласка, назву банку')
    .min(3, 'Введіть, будь ласка, назву банку'),
  ipn: string().when('isPassport', {
    is: false,
    then: string()
      .required('Введіть, будь ласка, коректні дані')
      .matches(/^\d{10}$/g, 'Введіть, будь ласка, коректні дані'),
  }),
  passport: string().when('isPassport', {
    is: true,
    then: string().matches(
      /[А-ЯІЄЇҐ]{2}\d{6}$|^\d{9}$/g,
      'Введіть, будь ласка, коректні дані',
    ),
  }),

  isPassport: boolean(),
  name: string()
    .matches(
      /^[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']*\s[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']+(\s[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']+)?$/g,
      'Введіть прізвище, ім`я та по батькові',
    )
    .max(100, 'Некоректне прізвище, ім`я та по батькові'),
  description: string().required('Опишіть на що саме потрібні кошти'),
  tel: string()
    .required('Введіть, будь ласка, номер телефону')
    .matches(/^0{1}[1-9]{1}\d{8}/g, 'Некоректний номер телефона'),
  // .min(17, 'Введіть, будь ласка, номер телефону'),
  birthday: string().required('Обов`язкове поле'),

  iban: string()
    .required('Введіть, будь ласка, коректний рахунок')
    .matches(/^UA[0-9]{27}$/g, 'Введіть, будь ласка, коректний рахунок'),
  actual_address: object({
    id: number().min(1).required(),
  }),
  registration_address: object({
    id: number().min(1).required(),
  }),

  category: boolean(),
  economic_activity: boolean(),

  reg_address: object({
    house: string()
      .max(20, 'Введіть будь-ласка коректний номер будинку')
      .required('Оберіть, будь ласка, свою адресу'),
    apartment: string().max(99999, 'Некоректний номер квартири'),
    street: string()
      .required('Оберіть, будь ласка, свою адресу')
      .max(100, 'Введіть, будь ласка, коректну вулицю'),

    zip_code: string()
      .matches(/\d{5}/g, 'Некоректний індекс')
      .required('Оберіть, будь ласка, свою адресу'),
  }),

  act_address: object({
    house: string()
      .max(20, 'Введіть будь-ласка коректний номер будинку')
      .required('Оберіть, будь ласка, свою адресу'),
    apartment: string().max(99999, 'Некоректний номер квартири'),
    street: string()
      .required('Оберіть, будь ласка, свою адресу')
      .max(100, 'Введіть, будь ласка, коректну вулицю'),
    zip_code: string()
      .matches(/\d{5}/g, 'Некоректний індекс')
      .required('Оберіть, будь ласка, свою адресу'),
  }),
  economic_description: string().required(
    'Опишіть, будь ласка, Вашу діяльність',
  ),
  family_ties: string()
    .required('Вкажіть родинні зв`язки або зазначте, що Ви самі є ветераном')
    .min(3, 'Вкажіть родинні зв`язки або зазначте, що Ви самі є ветераном'),
  full_name_participant_hostilities: string()
    .matches(
      /^[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']*\s[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']+(\s[А-ЯІЄЇҐ][а-яА-ЯІЄЇҐієїґ\-\`\'\ʼ\']+)?$/g,
      'Введіть прізвище, ім`я та по батькові',
    )
    .max(100, 'Некоректне прізвище, ім`я та по батькові'),
  certificate_participant_hostilities: string()
    .required('Введіть, будь ласка, коректну cерію та номера посвідчення')
    .matches(
      /^[А-ЯІЄЇҐ0-9]{8,9}$/g,
      'Введіть, будь ласка, коректну cерію та номера посвідчення',
    ),
  correspondence_address: string()
    .required('Введіть, будь ласка, адресу для листування')
    .min(10, 'Введіть, будь ласка, адресу для листування'),
  price: number()
    .typeError('Введіть суму коштів')
    .required('Введіть суму коштів')
    .max(20000, 'Максимальна сума коштів – 20 тисяч гривень.')
    .min(0, 'Введіть коректну суму'),
  c1: boolean().isTrue('Обов`язковое'),
  c2: boolean().isTrue('Обов`язковое'),
  c3: boolean().isTrue('Обов`язковое'),
  c4: boolean().isTrue('Обов`язковое'),
  citizen_documents: array().test(
    'citizen_documents',
    'Завантажте файл!',
    (value = []) => {
      return !!value.length;
    },
  ),
  employed_documents: array().test(
    'employed_documents',
    'Завантажте файл!',
    (value = []) => {
      return !!value.length;
    },
  ),
  army_documents: array().test(
    'army_documents',
    'Завантажте файл!',
    (value = []) => {
      return !!value.length;
    },
  ),
  internally_documents: array().test(
    'internally_documents',
    'Завантажте файл!',
    (value = []) => {
      return !!value.length;
    },
  ),
  actual_costs_documents: array().test(
    'actual_costs_documents',
    'Завантажте файл!',
    (value = []) => {
      return !!value.length;
    },
  ),
});

import {AxiosPromise} from 'axios';
import axios from 'src/services/axios';
import {Statistic} from './namespace';

export const apiStatistic = new (class Api {
  fetchItems(
    params: Statistic.ReqFetchItems,
  ): AxiosPromise<Statistic.ResFetchItems> {
    return axios({
      url: '/statistics',
      method: 'get',
      params,
    });
  }
})();

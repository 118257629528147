import {all, put, call} from 'redux-saga/effects';
import {uiActions} from 'src/bus/ui';
import {SagaIterator} from 'redux-saga';
import {apiClaim} from '../../api';
import {CreateItemAsync} from '../../types';
import {AxiosResponse} from 'axios';
import {Claim} from '../../namespace';
import {claimActions} from '../../slice';
import {Routes} from 'src/routes';
import {showToast} from 'src/services/toast';

export function* createItem(action: CreateItemAsync): SagaIterator {
  try {
    yield put(uiActions.toggleLoader({name: 'claim', loading: true}));

    const response: AxiosResponse<Claim.ResCreateItem> = yield call(
      apiClaim.createItem,
      action.payload,
    );

    showToast({
      message: `Слава Україні! Ви успішно подали заяву на одноразове відшкодування витрат Українським ветеранським фондом. Ми найближчим часом повідомимо Вам про результати розгляду заяви на вказану Вами адресу електронної пошти.`,
      type: 'success',
      position: 'top-center',
      hideProgressBar: true,
      style: {
        width: 700,
        right: '60%',
        // right: 1000,
      },
      autoClose: false,
    });

    yield put(claimActions.toggleSuccess(true));

    yield put(claimActions.clearPreviewDettail());
    yield put(claimActions.clearDetail());

    // if (response.data) {
    // yield put(claimActions.savePreviewDetail(response.data.claim));

    // yield put(uiActions.openLink(Routes.CLAIM_PREVIEW));
    // }
  } catch (e) {
    console.log(`error create claim item worker ${e}`);
  } finally {
    yield put(uiActions.toggleLoader({name: 'claim', loading: false}));
  }
}
